<template>
  <div class="main">
    <h1 class="title is-1">Directory of Accredited Firms</h1>
    <h2 class="subtitle">Complete list of Accredited Law Firms & Offices for ALS Apprenticeship Program</h2>
    <b-message type="is-info" has-icon>
      <p>The following information is sourced from the <a href="https://www.ateneo.edu/aps/law/jd-program-law/apprenticeship-program">Ateneo Law School website.</a></p>
    </b-message>

    <div class="table-container">
      <table class="table">
        <thead>
        <tr>
          <th>
            Office/Organization
          </th>
          <th>
            Address
          </th>
          <th>
            Contact Person/Telephone No.
          </th>
        </tr>
        </thead>

        <tbody><tr><td>
          ANGARA ABELLO CONCEPCION REGALA &amp; CRUZ LAW OFFICES<br>
          (ACCRALAW)<br>
          &nbsp;</td>
          <td>
            22nd-26th Floor, ACCRALAW TOWER 2nd Avenue corner 30th Street, Crescent Park West, Bonifacio Global City, 0399 Taguig, Metro Manila, Philippines</td>
          <td>
            Atty. Regina Padilla Geraldez<br>
            Co-Managing Partner<br>
            Partner in Charge of Hiring<br>
            And Apprenticeship Program<br>
            T: + 63 (02) 830 8000<br>
            F: + 63 (02) 403 7007/403 7009<br>
            Email: <a href="mailto:accra@law.com">accra@law.com</a><br>
            Website: <a href="http://www.accralaw.com">www.accralaw.com</a></td>
        </tr><tr><td>
          ARTUZ BELLO &amp; BORJA LAW OFFICES</td>
          <td>
            Rm. 210 Golden Sun Realty Building, No. 29 Mindanao Avenue, Pag-asa Quezon City, Metro Manila 1105</td>
          <td>
            Atty. Rodrigo R.G. Artuz<br>
            Atty. Melanie Rose Marie B. Radovan<br>
            T: + 63 (02) 456 2228<br>
            F: + 63 (02) 456 6391<br>
            Email: <a href="mailto:abblaw210@gmail.com">abblaw210@gmail.com</a></td>
        </tr><tr><td>
          ASPIRAS &amp; ASPIRAS LAW OFFICES</td>
          <td>
            Unit 1009 Prestige Tower, F. Ortigas Jr. Road (formerly Emerald Avenue), Ortigas&nbsp; Center, 1605 Pasig City, Philippines</td>
          <td>
            Atty. Thea Lim Magallanes<br>
            T: + 63 (02) 910 5826 to27<br>
            F: + 63 (02) 910 5827<br>
            Email: <a href="mailto:cvaspiras@gmail.com">cvaspiras@gmail.com</a><br>
            Website: <a href="http://www.aspiraslaw.com/">www.aspiraslaw.com</a></td>
        </tr><tr><td>
          ASTORGA &amp; REPOL LAW OFFICES</td>
          <td>
            Unit 2105 Philippine Axa Life Centre, Sen. Gil Puyat Avenue cor. Ayana Avenue, Makati City, 1203 Metro Manila</td>
          <td>
            Atty. Omar U. Obias<br>
            T: + 63 (02) 886 7943/889 6518<br>
            F: + 63 (02) 887 3104<br>
            Email: <a href="mailto:astorgaandrepol@arlaw.com.ph">astorgaandrepol@arlaw.com.ph</a><br>
            Website: <a href="http://www.arlaw.com.ph/">www.arlaw.com.ph</a></td>
        </tr><tr><td>
          B.J. CONG &amp; ASSOCIATES</td>
          <td>
            Unit 11-A, Lumier Chateau Building, 285 P. Guevarra, 1500 San Juan, San Juan Philippines</td>
          <td>
            Atty. Benzon Judd Cong<br>
            Mr. Arnulfo Tarrayo<br>
            T: + 63 (02) 584&nbsp; 1807<br>
            F: + 63 (02) 584 1807<br>
            Email: <a href="mailto:info@bjconglaw.net">info@bjconglaw.net</a></td>
        </tr><tr><td>
          BANIQUED &amp; BANIQUED LAW OFFICES (BANIQUED LAYUG &amp; BELLO ATTORNEYS AT LAW)</td>
          <td>
            8<sup>th</sup> Floor, Jollibee Center, San Miguel Avenue, 1605 Pasig City</td>
          <td>
            Atty. Laura Victoria Yuson-Layug<br>
            T: + 63 (02) 633 9418<br>
            F: + 63 (02) 633 1911</td>
        </tr><tr><td>
          BATINO LAW OFFICES</td>
          <td>
            4<sup>th</sup> Floor, BMG Centre, San Antonio Street, Paseo de Magallanes, Makati City</td>
          <td>
            Atty. Jose Roberto D. Bolante<br>
            T: + 63 (02) 810 7246 to 49<br>
            F: + 63 (02)<br>
            Email: <a href="mailto:batinolaw@batinolaw.com">batinolaw@batinolaw.com</a></td>
        </tr><tr><td>
          BENGZON NEGRE UNTALAN</td>
          <td>
            2<sup>nd </sup>Floor, Sedcco Building, Rada cor. Legaspi Streets,&nbsp; Makati City 1229 Philippines</td>
          <td>
            Atty. Jonathan Q. Perez<br>
            T: + 63 (02) 813 0120<br>
            F: + 63 (02) 894 2073<br>
            Email: <a href="mailto:bnu@iplaw.ph">bnu@iplaw.ph</a><br>
            Website: <a href="http://www.iplaw.ph/">www.iplaw.ph</a></td>
        </tr><tr><td>
          BERNAS LAW OFFICE</td>
          <td>
            8<sup>th</sup> Floor, Raha Sulayman Building, 108 Benavidez Street, Legaspi Village, Makati City 1229, Philippines</td>
          <td>
            Atty. Joanne Mae Bibal<br>
            Atty. Angelica Dimalanta<br>
            T: + 63 (02) 812 8994/810 1814<br>
            F: + 63 (02) 892 1770<br>
            Email: <a href="mailto:bernaslaw@gmail.com">bernaslaw@gmail.com</a><br>
            Website: <a href="http://www.bernaslaw.com">www.bernaslaw.com</a></td>
        </tr><tr><td>
          BETITA CABILAO CASUELA SARMIENTO (BCCS LAW)</td>
          <td>
            Suite 1104, Page One Building<br>
            1215 Acacia Avenue, Madrigal Business Park, Ayala Alabang, Muntinlupa City Metro Manila, Philippines 1780</td>
          <td>
            Atty. Rhealeth D. Ramos<br>
            T: + 63 (02) 555 1750<br>
            &nbsp;&nbsp;&nbsp;&nbsp; + 63 (02) 822 4665<br>
            F:&nbsp; + 63 (02) 822 4665<br>
            Email: <a href="mailto:legal@bccslaw.com">legal@bccslaw.com</a><br>
            Web: <a href="http://www.bccslaw.com">www.bccslaw.com</a></td>
        </tr><tr><td>
          BRITANICO SARMIENTO &amp; RINGLER LAW OFFICES</td>
          <td>
            7<sup>th</sup> Floor, Banco de Oro Plaza, 8737 Paseo de Roxas, 1200 Makati City</td>
          <td>
            Atty. Rodolf C. Britanico<br>
            Atty. Chelsea Joyce C. Ballesteros<br>
            T: + 63 (02) 813 2837/812 8041/867 4502/867 4505<br>
            F: + 63 (02) 813 2838<br>
            Email: <a href="mailto:info@pbsflawfirm.com">info@pbsflawfirm.com</a><br>
            Website: pbsflawfirm.com</td>
        </tr><tr><td>
          CALLEJA LAW OFFICE</td>
          <td>
            Unit 2904-C West Tower PSE Centre, Exchange Road, Ortigas Center, Pasig City</td>
          <td>
            Atty. Howard M. Calleja<br>
            Ms. Lulu Lagonoy<br>
            T: + 63 (02) 633 6113/635 2307<br>
            F: + 63 (02) 635 5786<br>
            Email: <a href="mailto:calleja@callejalaw.com">calleja@callejalaw.com</a></td>
        </tr><tr><td>
          CASTILLO LAMAN TAN PANTALEON &amp; SAN JOSE</td>
          <td>
            The Valero Tower<br>
            122 Valero Street, Salcedo Village, 1227 Makati City, Philippines</td>
          <td>
            Atty. Mel A. Macaraig<br>
            Atty. Yolanda M. Eleazar<br>
            Atty. Rita Mesina-Alvaera<br>
            T: + 63 (02) 817 6791 to 95<br>
            F: + 63 (02) 819 2724 to 25<br>
            Email: <a href="mailto:counsel@cltpsj.com.ph">counsel@cltpsj.com.ph</a><br>
            Web: <a href="http://www.cltpsj.com.ph/">www.cltpsj.com.ph</a></td>
        </tr><tr><td>
          CAYETANO SEBASTIAN ATA DADO &amp; CRUZ LAW OFFICE</td>
          <td>
            12<sup>th</sup> Floor, NDC Building, 116 Tordesillas Street, Salcedo Village, Makati City, Philippines</td>
          <td>
            Atty. Alfred S. Jacinto<br>
            Atty. Rene Andrew A. Guina<br>
            T: + 63 (02) 841 0716<br>
            F: + 63 (02) 841 0544<br>
            Email: <a href="mailto:info@caselaw.com.ph">info@caselaw.com.ph</a><br>
            Web: <a href="http://www.caselaw.com.ph/">www.caselaw.com.ph</a></td>
        </tr><tr><td>
          CHAVEZ MIRANDA &amp; ASEOCHE LAW OFFICES</td>
          <td>
            8<sup>th</sup> Floor, One Corporate Plaza, 845 Antonio Arnaiz Avenue, San Lorenzo Village, Makati City, 1223 Metro Manila</td>
          <td>
            Atty. Stephanie R. Chavez<br>
            Atty. Don Carlo R. Ybañez<br>
            T: + 63 (02) 810 9701 to 04<br>
            F: + 63 (02) 810 9703<br>
            Email: <a href="mailto:cma@cmalaw.ph">cma@cmalaw.ph</a></td>
        </tr><tr><td>
          COCHINGYAN &amp; PERALTA LAW OFFICES</td>
          <td>
            12<sup>th</sup> Floor, 139 Corporate Center, 139 Valero Street, Salcedo Village, Makati City, 1227 Metro Manila</td>
          <td>
            Atty. Ma. Christina Reyes<br>
            T: + 63 (02) 817 3081 to 83<br>
            F: + 63 (02) 812 0674<br>
            Email: <a href="mailto:info@cochingyanlaw.com">info@cochingyanlaw.com</a></td>
        </tr><tr><td>
          CRUZ MARCELO &amp; TENEFRANCIA</td>
          <td>
            9<sup>th</sup>, 10<sup>th</sup>, 11<sup>th</sup> &amp; 12<sup>th</sup> Floors, One Orion, 11<sup>th</sup> Avenue corner University Parkway, Bonifacio Global City 1634, Metro Manila, Philippines</td>
          <td>
            Atty. John Balisnomo<br>
            T: + 63 (02) 810 5858<br>
            F: + 63 (02) 810 3838<br>
            Email: <a href="mailto:info@cruzmarcelo.com">info@cruzmarcelo.com</a><br>
            Website:<br><a href="http://www.cruzmarcelo.com/">www.cruzmarcelo.com</a></td>
        </tr><tr><td>
          CUSTODIO ACORDA SICAM DE CASTRO &amp; PANGANIBAN LAW OFFICES</td>
          <td>
            15<sup>th</sup> Floor, Citibank Tower, 8741 Paseo de Roxas, corner Villar Street, Salcedo Village, Makati City</td>
          <td>
            Atty. Joaquin Miguel Z. Hizon<br>
            T: + 63 (02) 819 1624/819 5051-53<br>
            F: + 63 (02)819 2978<br>
            Email: <a href="mailto:custodio@custodiolawfirm.com">custodio@custodiolawfirm.com</a><br>
            Website: <a href="http://www.custodiolawfirm.com">www.custodiolawfirm.com</a></td>
        </tr><tr><td>
          DU-BALADAD AND ASSOCIATES (BDB LAW)</td>
          <td>
            20<sup>th</sup> Floor, Chatham House, Rufino cor. Valero Streets Salcedo Village, 1227 Makati City, Philippines</td>
          <td>
            Atty. Pierre Martin D. Reyes<br>
            T: + 63 (02) 403 2001<br>
            F: + 63 (02) 403 2001 loc. 130<br>
            Email: <a href="mailto:info@bdblaw.com.ph">info@bdblaw.com.ph</a><br>
            Website: <a href="http://www.bdblaw.com.ph">www.bdblaw.com.ph</a></td>
        </tr><tr><td>
          ESCUDERO MARASIGAN VALLENTE &amp; E.H. VILLAREAL LAW FIRM (EMSAVILLAW)</td>
          <td>
            Penthouse 3, Manila Luxury Condominium, Pearl Drive cor. Goldloop, Ortigas Center, Pasig City<br>
            Philippines 1605</td>
          <td>
            Atty. Michael Gerard T. Victoriano<br>
            T: + 63 (02) 631 5280 to 81/638 6095 to 96<br>
            F: + 63 (02) 638 6097<br>
            Email: <a href="mailto:helpdesk@emsavvil.com">helpdesk@emsavvil.com</a><br>
            Website: <a href="http://www.emsavvil.com">www.emsavvil.com</a></td>
        </tr><tr><td>
          ESGUERRA DE JESUS DY AND CHICO</td>
          <td>
            Unit 706, Tycoon Centre, Pearl Drive, Ortigas Center 1605, Pasig City</td>
          <td>
            Atty. Andre R. De Jesus<br>
            T: + 63 (02) 719 0845<br>
            F: + 63 (02) 910 4893<br>
            Email: <a href="mailto:info@ejdclaw.com">info@ejdclaw.com</a></td>
        </tr><tr><td>
          FERIA TANTOCO DAOS LAW OFFICE</td>
          <td>
            8<sup>th</sup> Floor, DPC Place,2322 Chino Roces Avenue, Makati City, 1231 Metro Manila</td>
          <td>
            Atty. Jan Mari Adan<br>
            T: + 63 (02) 889 8677<br>
            F: + 63 (02) 889 8684<br>
            Email: <a href="mailto:contactus@ferialaw.com">contactus@ferialaw.com</a><br>
            Website: <a href="http://www.ferialaw.com">www.ferialaw.com</a></td>
        </tr><tr><td>
          FLAMINIANO ARROYO &amp; DUENAS LAW OFFICE</td>
          <td>
            1002 One Corporate Centre, Meralco Avenue, cor. Doña Julia Vargas Avenue, Ortigas Center, Pasig City, Philippines 1605</td>
          <td>
            Atty. Jonathan C. Flaminiano<br>
            Atty. Jesi Howard S. Lanete<br>
            T: + 63 (02) 584 8461 to 62<br>
            F: + 63 (02) 655 8329<br>
            Email: <a href="mailto:jonathan.flaminiano@fadslaw.com">jonathan.flaminiano@fadslaw.com</a><br><a href="mailto:Jesi.lanete@fadslaw.com">Jesi.lanete@fadslaw.com</a><br>
            Website: <a href="http://www.fadslaw.com">www.fadslaw.com</a></td>
        </tr><tr><td>
          FOLLOSCO MORALLOS &amp; HERCE</td>
          <td>
            25<sup>th</sup> Floor, 88 Corporate Center, 141 Valero cor. Sedeno Streets., Salcedo Village, Makati City 1227, Philippines</td>
          <td>
            Atty. Marie Christine Sagrado-Cabato<br>
            T: + 63 (02) 889 0808/752 2215<br>
            F: + 63 (02) 752 2217<br>
            Email: <a href="mailto:info@fmh.ph">info@fmh.ph</a><br>
            Website: <a href="http://www.fmh.ph">www.fmh.ph</a></td>
        </tr><tr><td>
          GATMAYTAN YAP PATACSIL GUTIERREZ &amp; PROTACIO (FORMER CAGAT)</td>
          <td>
            30<sup>th</sup> Floor, 88 Corporate Center, Sedeño cor. Valero Streets, Salcedo Village, Makati City 1227, Philippines</td>
          <td>
            Atty. Jaime Renato B. Gatmaytan<br>
            T: + 63 (02) 894 0377 to 79/894 4931 to 32/552 1977/901 4105<br>
            F: + 63 (02) 552 1978<br>
            Email: <a href="mailto:cglaw@cagatlaw.com">cglaw@cagatlaw.com</a><br>
            Website: <a href="http://www.cagatlaw.com">www.cagatlaw.com</a></td>
        </tr><tr><td>
          GERODIAS SUCHIANGO ESTRELLA LAW OFFICES</td>
          <td>
            Suite 2404 Discovery Center, 25 ADB Avenue, Ortigas Center, Pasig City, 1605 Philippines</td>
          <td>
            Atty. Jansen F. Bernardo<br>
            Atty. Cheryl S. De Leon<br>
            T: + 63 (02) 634 2935<br>
            F: + 63 (02) 637 2887<br>
            Email: <a href="mailto:inquire@GSElawfirm.com">inquire@GSElawfirm.com</a><br>
            Website: <a href="http://www.GSElawfirm.com">www.GSElawfirm.com</a></td>
        </tr><tr><td>
          GO &amp; UMALI PARTNERS-AT-LAW<br>
          &nbsp;<br>
          &nbsp;</td>
          <td>
            Unit 12A-12<sup>th</sup> Floor, Marco Polo Hotel Ortigas Manila, Meralco Avenue, cor. Sapphire Road, Ortigas Center, San Antonio Pasig, Metro Manila</td>
          <td>
            Atty. Erdelyne Go<br>
            Atty. Mildred Yovela Umali<br>
            T; + 63 (02) 535-5522</td>
        </tr><tr><td>
          GONZALES BATILLER LEABRES &amp; REYES &amp; ASSOCIATES</td>
          <td>
            7<sup>th</sup> Floor, ALPAP I Building, 140 L.P. Leviste Street, Salcedo Village, Makati City</td>
          <td>
            Atty. Gavino F. Reyes<br>
            T: + 63 (02) 815 8701 to 03<br>
            F: + 63 (02) 819 2097<br>
            Website: <a href="http://www.gblrlaw.com">www.gblrlaw.com</a></td>
        </tr><tr><td>
          GULAPA LAW OFFICE</td>
          <td>
            12-A, 12/F Marco Polo Ortigas Manila, Sapphire Road, Ortigas Center, Pasig City 1605 Philippines</td>
          <td>
            Atty. Maureen Z. Macaraeg<br>
            Atty. Ailee Sanguir<br>
            T: + 63 (02) 960 2845 to 47<br>
            F: + 63 (02) 857 2240<br>
            Web: <a href="http://www.gulapalaw.com/">www.gulapalaw.com</a></td>
        </tr><tr><td>
          HERRERA TEEHANKEE &amp; CABRERA LAW OFFICE</td>
          <td>
            5<sup>th</sup> Floor, SGV II Building, 6758 Ayala Avenue, Makati City, 1200 Philippines</td>
          <td>
            Atty. Felippe Mart E. Closa<br>
            T: + 63 (02) 813 7111 to 14/894 4470 to 71/894 4392<br>
            F: + 63 (02) 813 7881/840 5555<br>
            Email: <a href="mailto:htc@htc-law.com.ph">htc@htc-law.com.ph</a><br>
            Website: <a href="http://www.htc-law.com.ph">www.htc-law.com.ph</a></td>
        </tr><tr><td>
          KING CAPUCHINO TAN AND ASSOCIATES</td>
          <td>
            2<sup>nd</sup> Floor, Belman II Building, Quezon Avenue, cor. Cordillera Street, Quezon City, 1114 Metro Manila</td>
          <td>
            Atty. Hermenegildo C. Capuchino II<br>
            T: + 63 (02) 711 4316/712 2972/712 2976<br>
            F: + 63 (02) 732 9204<br>
            Email. <a href="mailto:Kcta_law@yahoo.com">Kcta_law@yahoo.com</a></td>
        </tr><tr><td>
          KPMG R.G. Manabat & Co.</td>
          <td>
            The KPMG Center, 6/F 6787 Ayala Avenue, Makati City 1209, Philippines</td>
          <td>
            Monica Isabel C. Montemayor<br>
            T: +63 (02) 8885 7000<br>
            Email: <a href="mcmontemayor1@kpmg.com">mcmontemayor1@kpmg.com</a><br>
            Website: <a href="www.kpmg.com/ph">www.kpmg.com/ph</a> </td>
        </tr><tr><td>
          LIGON SOLIS MEJIA FLORENDO &amp; CRUZ</td>
          <td>
            The Penthouse Zeta Building, 191 Salcedo Street, Legaspi Village, Makati City 1200</td>
          <td>
            Atty. Charity Pedrajas<br>
            T: + 63 (02) 817 5672/894 0601<br>
            F: + 63 (02) 894 5737</td>
        </tr><tr><td>
          MATA-PEREZ &amp; FRANCISCO ATTORNEYS AT LAW</td>
          <td>
            Unit 15A, A.C.T. Tower, 135 H.V. Dela Costa Street, Salcedo Village, Makati City 1227, Philippines</td>
          <td>
            Atty. Gerardo Maximo V. Francisco<br>
            T: + 63 (02) 831 1297<br>
            F: + 63 (02) 815 0069<br>
            Email: <a href="mailto:info@mpfcounsel.com">info@mpfcounsel.com</a><br>
            Website: <a href="http://www.mtfcounsel.com/">www.mtfcounsel.com</a></td>
        </tr><tr><td>
          MEDIALDIA ATA BELLO GUEVARRA &amp; SUAREZ LAW OFFICES</td>
          <td>
            Unit 1704, 17<sup>th</sup> Floor, The Taipan Place, F. Ortigas Jr. Road, Ortigas Center, Pasig City</td>
          <td>
            Atty. Lorna Imelda M. Suarez<br>
            Atty. Golda Margareth Argel<br>
            T: + 63 (02) 635 4276 to 83<br>
            F: + 63 (02) 635 5279/635 5283<br>
            Email: <a href="mailto:mailbox@mabgslaw.com.ph">mailbox@mabgslaw.com.ph</a><br>
            Website: <a href="http://www.mabgslaw.com.ph">www.mabgslaw.com.ph</a></td>
        </tr><tr><td>
          MIGALLOS &amp; LUNA LAW OFFICES</td>
          <td>
            7<sup>th</sup> Floor, The PHINMA Plaza, 39 Plaza Drive, Rockwell Center, Makati City, 1210 Philippines</td>
          <td>
            Atty. Liselle Angela I. Bascara<br>
            T: + 63 (02) 896 9357 to 59<br>
            F: + 63 (02) 899 1833<br>
            Email: <a href="mailto:partners@migalloslunalaw.com">partners@migalloslunalaw.com</a><br>
            Website: <a href="http://www.migalloslunalaw.com">www.migalloslunalaw.com</a></td>
        </tr><tr><td>
          MOLO SIA VELASCO TUAZON AND TY LAW OFFICE</td>
          <td>
            Unit 901, 9<sup>th</sup> Floor, One Corporate Center, Doña Julia Vargas Avenue, cor. Meralco Avenue, Ortigas Center, Pasig City</td>
          <td>
            Atty. Aldrich Fitz U. Dy.<br>
            T: + 63 (02) 656 4096/656 4098<br>
            F: + 63 (02) 656 4097<br>
            Email: <a href="mailto:info@mosveldttlaw.com">info@mosveldttlaw.com</a></td>
        </tr><tr><td>
          NISCE MAMURIC GUINTO RIVERA &amp; ALCANTARA LAW</td>
          <td>
            8<sup>th</sup> Floor, 139 Corporate Center, 139 Valero Street, Salcedo Village, Makati City, 1227 Metro Manila</td>
          <td>
            Atty. Kenneth C. De Jesus<br>
            T: + 63 (02) 751 2222<br>
            F: + 63 (02) 751 2405<br>
            Email: <a href="mailto:counselors@nmgra-law.com">counselors@nmgra-law.com</a><br>
            Website: <a href="http://www.nmgra-law.com">www.nmgra-law.com</a></td>
        </tr><tr><td>
          ONG MENESES GONZALEZ &amp; GUPIT LAW OFFICE</td>
          <td>
            18<sup>th</sup> Floor, 88 Corporate Center, 141 Valero cor. Sedeo Streets, Salcedo Village, Makati City, 1227 Philippines</td>
          <td>
            Atty. James A. Jumalon<br>
            T: + 63 (02) 836 8800 to 03<br>
            F: + 63 (02) 836 9900<br>
            Email: <a href="mailto:attorneys@omgglaw.com">attorneys@omgglaw.com</a><br>
            Website: <a href="http://www.omgglaw.com">www.omgglaw.com</a></td>
        </tr><tr><td>
          PARAS &amp; MANLAPAZ LAW OFFICE</td>
          <td>
            Unit 1402 BDO Equitable Tower, 8751 Paseo de Roxas, Makati City 1227</td>
          <td>
            Atty. Katherine Josel M. Manungsong<br>
            T: + 63 (02) 817 3442/817 3455/817 3484<br>
            F: + 63 (02) 817 3508<br>
            Email: <a href="mailto:lawyers@parasmanlapazlawyers.com">lawyers@parasmanlapazlawyers.com</a><br>
            Website: <a href="http://www.parasmanlapazlawyers.com">www.parasmanlapazlawyers.com</a></td>
        </tr><tr><td>
          PICAZO BUYCO TAN FIDER &amp; SANTOS LAW OFFICES</td>
          <td>
            Penthouse, Liberty Center, 104 H.V. Dela Costa Street, Salcedo Village, Makati City, 1227 Metro Manila, Philippines</td>
          <td>
            Atty. Luis Manuel L. Gatmaitan<br>
            T: + 63 (02) 888 0999/888 0311<br>
            F: + 63 (02) 888 1012/888 1011<br>
            Email: <a href="mailto:legalstaff@picazolaw.com">legalstaff@picazolaw.com</a><br>
            Website: <a href="http://www.picazolaw.com">www.picazolaw.com</a></td>
        </tr><tr><td>
          PLACIDO &amp; CHAN LAW OFFICES (BPC LAW)</td>
          <td>
            Unit 701 Antel Corporate Centre, 121 Valero Street, Salcedo Village, Makati City 1227, Philippines</td>
          <td>
            Atty. Dennis Arvin L. Chan<br>
            T: + 63 (02) 800 8380 to 81<br>
            F: + 63 (02) 800 8386<br>
            Email: <a href="mailto:admin@bpclawoffices.com">admin@bpclawoffices.com</a></td>
        </tr><tr><td>
          POBLADOR BAUTISTA &amp; REYES LAW OFFICES</td>
          <td>
            5<sup>th</sup> Floor, SEDCCO I Building<br>
            120 Rada Street, Legaspi Village, Makati City, Metro Manila 1229, Philippines</td>
          <td>
            Atty. Marie Cecille R. Quintos<br>
            T: + 63 (02) 893 7623<br>
            F: + 63 (02) 893 7622<br>
            Email: <a href="mailto:central@pbrlaw.com.ph">central@pbrlaw.com.ph</a></td>
        </tr><tr><td>
          PRICEWATERHOUSECOOPERS (PWC)</td>
          <td>
            29<sup>th </sup>Floor, Philamlife Tower, 8767 Paseo de Roxas, Makati City 1226, Philippines</td>
          <td>
            Ms.&nbsp; Yochika Nakagawa<br>
            Mr. Jeff Mitchel G. Garcia<br>
            HR Associates<br>
            T: + 63 (02) 845 2728<br>
            F: + 63 (02) 845 2806<br>
            Email: <a href="mailto:markets@ph.pwc.com">markets@ph.pwc.com</a></td>
        </tr><tr><td>
          PUNO &amp; PUNO LAW OFFICES</td>
          <td>
            12/F, East Tower, Philippine Stock Exchange Center, Exchange Road, Ortigas Center, Pasig City</td>
          <td>
            Ms. Maria Theresa D. Quimpo<br>
            HR Manager<br>
            T: + 63 (02) 631 1261<br>
            F: + 63 (02) 631 2517<br>
            C: 0915 251 1901<br>
            Email: <a href="mailto:info@punolaw.com">info@punolaw.com</a></td>
        </tr><tr><td>
          PUYAT JACINTO &amp; SANTOS LAW OFFICES (PJS LAW)</td>
          <td>
            10<sup>th </sup>Floor, 8 Rockwell, Hidalgo cor. Plaza Drive, Rockwell Center, Makati City 1200, Philippines</td>
          <td>
            Atty. Rowena M. Salonga-Abaya<br>
            T: + 63 (02) 840 5025 to 28<br>
            F: + 63 (02) 810 0890/817 5969<br>
            Email: <a href="mailto:pjs@pjslaw.com">pjs@pjslaw.com</a><br>
            Website: <a href="http://www.pjslaw.com/">www.pjslaw.com</a></td>
        </tr><tr><td>
          QUASHA ANCHETA PEÑA &amp; NOLASCO LAW OFFICES</td>
          <td>
            6<sup>th</sup> Floor, Don Pablo Building, 114 Amorsolo Street, Legazpi Village, Makati City</td>
          <td>
            Atty. Jasmine M. Jimenez<br>
            T: + 63 (02) 892 3011/892 5736<br>
            F: + 63 (02) 817 6423/893 1356<br>
            Email: <a href="mailto:quasha@quasha-interlaw.com">quasha@quasha-interlaw.com</a><br>
            Website: <a href="http://www.quasha-interlaw.com">www.quasha-interlaw.com</a></td>
        </tr><tr><td>
          THE LAW FIRM OF QUIASON MAKALINTAL BAROT TORRES IBARRA SISON &amp; DAMASO</td>
          <td>
            21<sup>st</sup> Floor, Robinsons Equitable Tower 4, ADB Avenue, cor. Pedro Poveda Street, 1605 Ortigas Center, Pasig City Philippines</td>
          <td>
            Atty. Ruelito Q. Soriano<br>
            T: + 63 (02) 631 0981 to 85<br>
            F: + 63 (02) 631 3847<br>
            Email: <a href="mailto:qmbti@qmbti.com">qmbti@qmbti.com</a><br>
            Website: <a href="http://www.qmbti.com/">www.qmbti.com</a></td>
        </tr><tr><td>
          QUISUMBING TORRES LAW OFFICES</td>
          <td>
            12<sup>th</sup> Floor, Net One Center, 26 Street cor. 3<sup>rd</sup> Avenue, Crescent Part West, Bonifacio Global City, Taguig City 1634 Philippines</td>
          <td>
            Atty. Rodrigo Lope S. Quimbo<br>
            T: + 63 (02) 819 4700<br>
            F: + 63 (02) 816 0080/728 7777</td>
        </tr><tr><td>
          RIVERA SANTOS &amp; MARANAN LAW OFFICES ASSOCIATES</td>
          <td>
            2902 D. West Tower, Philippine Stock Exchange Centre, Exchange Road, Ortigas Center, Pasig City</td>
          <td>
            Atty. Ronaldo M. Caringal<br>
            T: + 63 (02) 635 6359 to 62<br>
            F: + 63 (02) 635 4775</td>
        </tr><tr><td>
          ROMULO MABANTA BUENAVENTURA SAYOC &amp; LOS ANGELES</td>
          <td>
            21<sup>st</sup> Floor, Philamlife Tower, 8767 Paseo de Roxas, Makati City 1226, Philippines</td>
          <td>
            Atty. Lee Benjamin Z. Lerma<br>
            T: + 63 (02) 555 9555/848 0114<br>
            F: + 63 (02) 815 3172/810 3110<br>
            Email: <a href="mailto:romulo@romulo.com">romulo@romulo.com</a><br>
            Website: <a href="http://www.romulo.com">www.romulo.com</a></td>
        </tr><tr><td>
          SAPALO VELEZ BUDANG &amp; BULILAN LAW OFFICES</td>
          <td>
            11<sup>th</sup> Floor, Security Bank Centre, 6776 Ayala Avenue, Makati City 1200, Metro Manila</td>
          <td>
            Atty. Romeo H. Duran<br>
            T: + 63 (02) 891 1316<br>
            F: + 63 (02) 891 1198<br>
            Email: <a href="mailto:info@sapalovelez.com">info@sapalovelez.com</a><br>
            Website: <a href="http://www.sapalovelez.com">www.sapalovelez.com</a></td>
        </tr><tr><td>
          SUBIDO PAGENTE CERTEZA MENDOZA &amp; BINAY</td>
          <td>
            5<sup>th</sup> Floor, Prince Building, 117 Rada Street, Legaspi Village, Makati City, Metro Manila</td>
          <td>
            Atty. Ma. Joycelyn L. Guirnalda<br>
            T: + 63 (02) 893 4115<br>
            F: + 63 (02)<br>
            Email: <a href="mailto:spcmb@spcmblawfirm.com">spcmb@spcmblawfirm.com</a><br>
            Website: spcmblawfirm.com</td>
        </tr><tr><td>
          SYCIP GORRES VELAYO &amp; CO. (SGV &amp; CO.)</td>
          <td>
            6760 Ayala Avenue, Makati City 1226, Metro Manila, Philippines</td>
          <td>
            Atty. Luis P. Ferrer<br>
            Atty. Julie Christine O. Mateo<br>
            T: + 63 (02) 891 0307<br>
            F: + 63 (02) 819 0872<br>
            &nbsp;&nbsp;&nbsp; + 63 (02) 818 1377</td>
        </tr><tr><td>
          SYCIP SALAZAR HERNANDEZ AND GATMAITAN LAW OFFICE</td>
          <td>
            Sycip Law Center, 105 Paseo de Roxas, Makati City, 1226 Metro Manila, Philippines</td>
          <td>
            Atty. Ramon G. Songco<br>
            T: + 63 (02) 982 3500/982 3600/982 3700<br>
            F: + 63 (02) 817 3896/818 7562<br>
            Email: <a href="mailto:sshg@syciplaw.com">sshg@syciplaw.com</a><br>
            Website: <a href="http://www.syciplaw.com">www.syciplaw.com</a></td>
        </tr><tr><td>
          TAN VENTURANZA VALDEZ LAW FIRM</td>
          <td>
            2704 East Tower Philippines Stock Exchange Centre Ortigas Center, Pasig City, Metro Manila, Republic of the Philippines</td>
          <td>
            Atty. Conrad S. Tolentino<br>
            T: + 63 (02) 632 0905<br>
            F: + 63 (02) 635 4703<br>
            Email: <a href="mailto:info@tvvlaw.com">info@tvvlaw.com</a><br>
            Website: <a href="http://www.tvvlaw.com/">www.tvvlaw.com</a></td>
        </tr><tr><td>
          TAPALAS PRODON WEE-TOE HIO&nbsp; TATEL &amp; RODRIGUEZ</td>
          <td>
            Ground Floor, One Beatriz Tower, Aurora Boulevard corner Lauan Street, Cubao Quezon City</td>
          <td>
            Atty. Nancy R. Prodon<br>
            T: +32 (02) 753-3572<br>
            Website: <a href="http://www.tapaleslaw.com">www.tapaleslaw.com</a><br>
            E-mail: <a href="mailto:centralmail@tapaleslaw.com">centralmail@tapaleslaw.com</a></td>
        </tr><tr><td>
          TAVIDELL TANTOCO VILLANUEVA DE GUZMAN &amp; LLMAS LAW OFFICE</td>
          <td>
            4<sup>th</sup> and 6<sup>th</sup> Floors, Filipino Building, 135 Dela Rosa Street, Legaspi Village, Makati City 1229, Philippines</td>
          <td>
            Atty. Reagan F. De Guzman<br>
            T: + 63 (02) 830 0497/813 9061/813 4722 to 23<br>
            F: + 63 (02) 813 8414<br>
            Email: <a href="mailto:tavidell@tavidell.com.ph">tavidell@tavidell.com.ph</a><br>
            Website: tavidell.com.ph</td>
        </tr><tr><td>
          TIONGCO SIAO BELLO LAW OFFICES</td>
          <td>
            2104-05 Robinsons Equitable Tower, 4 ADB Avenue, Ortigas Center, Pasig City</td>
          <td>
            Atty. Greg Joseph SJ. Tiongco<br>
            T: + 63 (02) 634 6469 to 71<br>
            F: + 63 (02) 633 0927<br>
            Email: <a href="mailto:info@tiongcosiaobellolaw.com">info@tiongcosiaobellolaw.com</a><br>
            Website: <a href="http://www.tiongcosiaobellolaw.com">www.tiongcosiaobellolaw.com</a></td>
        </tr><tr><td>
          VILLARAZA &amp; ANGANGCO LAW OFFICES</td>
          <td>
            V &amp; A Law Center<br>
            11<sup>th</sup> Avenue, cor. 39<sup>th</sup> Street, Bonifacio Global City 1634 Metro Manila, Philippines</td>
          <td>
            Atty. Ruth Nichole R. Ureta<br>
            T: + 63 (02) 988 6088<br>
            F: + 63 (02) 988 6000<br>
            Email: <a href="mailto:info@thefirmva.com">info@thefirmva.com</a><br>
            Website: thefirmva.com</td>
        </tr><tr><td>
          VILLANUEVA GABIONZA &amp; DY LAW OFFICES (VGS LAW)</td>
          <td>
            20<sup>th</sup> Floor, 139 Corporate Center, 139 Valero Street, Salcedo Village, Makati City, Metro Manila</td>
          <td>
            Atty. Stanley B. Fabito<br>
            T: + 63 (02) 813 3351<br>
            F: + 63 (02)<br>
            Email: <a href="mailto:attorneys@vgslaw.com">attorneys@vgslaw.com</a><br>
            Website: <a href="http://www.vgslaw.com">www.vgslaw.com</a></td>
        </tr><tr><td>
          ZAMBRANO, GRUBA, CAGANDA, ADVINCULA LAW OFFICE</td>
          <td>
            27<sup>th</sup> Floor, 88 Corporate Center, Sedeño Street, Salcedo Village, Makati City, Philippines</td>
          <td>
            Atty. Juan Miguel Victor C. De la Fuente<br>
            T: + 63 (02) 889 6060<br>
            F: + 63 (02) 889 6066<br>
            Email: <a href="mailto:zga@zglaw.com">zga@zglaw.com</a><br> <a href="mailto:mcdelafuente@zglaw.com">mcdelafuente@zglaw.com</a><br>
            Website: <a href="http://www.zglaw.com">www.zglaw.com</a></td>
        </tr></tbody></table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Directory"
}
</script>

<style scoped>
.main {
  padding: 6.9%;
}
</style>